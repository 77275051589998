import React from 'react';
import Header from './Header';

const AboutUs = () => {
  return (
    <div className='About-container'>
      <Header />
      <div className='about-inner'>Hakkımızda</div>
    </div>
  );
};

export default AboutUs;
